import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import _ from 'lodash';
import { useStaticQuery, graphql, Link } from 'gatsby';

import Layout from '../components/Layout';
import Content from '../components/Content';

const Container = styled.div`
  text-align: center;
`;

const ArchiveEntry = styled.div`
  text-align: left;
  display: flex;
  flex-wrap: wrap;
`;

const Archives = styled.div``;
const AuthorDate = styled.div`
display:flex;
  flex: 0 0 15rem;
`;
const Author = styled.div`
`;
const Date = styled.div`
  margin-left:2rem;
`;
const Title = styled.div`
  flex: 0 1 70%;
`;

const StyledLink = styled(Link)``;

const ListPostsPage = () => {
  const data = useStaticQuery(
    graphql`
      query ListPostsPage {
        allHtml(sort: { fields: [relativeDirectory], order: ASC }) {
          nodes {
            id
            relativeDirectory
            frontmatter {
              author
              date(formatString: "YYYY-MM-DD")
              title
            }
          }
        }
      }
    `
  );

  const {
    allHtml: { nodes },
  } = data;

  return (
    <Layout>
      <Helmet>
        <title>Legacy of Steel</title>
      </Helmet>
      <Content>
        <Container>
          <h1>Archives</h1>
          <Archives>
            {_.map(nodes, node => (
              <ArchiveEntry key={node.id}>
                <AuthorDate>
                  <Author>{node.frontmatter.date}</Author><Date>{node.frontmatter.author}</Date>
                </AuthorDate>
                <Title>
                  <StyledLink to={`archive/${node.relativeDirectory}`}>
                    {node.frontmatter.title}
                  </StyledLink>
                </Title>
              </ArchiveEntry>
            ))}
          </Archives>
        </Container>
      </Content>
    </Layout>
  );
};
export default ListPostsPage;
